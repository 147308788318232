import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PAGINATION } from "src/constants/common";

const initialState = {
  allConversations: [],
  allConversationsById: null,
};

const getAllConversationsById = (state: any, data: any) => {
  let allConversationsByIdData: { [key: string]: any } = {};

  if (state) {
    const jsonState = JSON.parse(JSON.stringify(state));
    if (jsonState?.allConversationsById) {
      allConversationsByIdData = jsonState?.allConversationsById;
    }
  }

  if (
    !data?.items
    || data.items.length === 0
    || !data.items[0]?.contactId
  ) {
    return {
      allConversationsById: allConversationsByIdData,
    };
  }

  const currentConversationUser = data.items[0].contactId.toString();

  if (!allConversationsByIdData) {
    const obj: any = {};
    obj[currentConversationUser] = {
      items: data.items,
      meta: data.meta,
    };
    return obj;
  }

  if (!data?.items) {
    return allConversationsByIdData;
  }

  if (!allConversationsByIdData[currentConversationUser]) {
    allConversationsByIdData[currentConversationUser] = {
      items: [],
      meta: null,
    };
  }

  if (data.meta?.page === PAGINATION.page) {
    allConversationsByIdData[currentConversationUser] = {
      items: data.items,
      meta: data.meta ?? null,
    };
  } else {
    const existingItems = new Set(allConversationsByIdData[currentConversationUser].map(
      (item: any) => item.id)
    );
    data.items.forEach((item: any) => {
      if (!existingItems.has(item.id)) {
        allConversationsByIdData[currentConversationUser].items.push(item);
        allConversationsByIdData[currentConversationUser].meta = data.meta;
      }
    });
    // allConversationsByIdData[currentConversationUser].push(...data.items);
  }

  // const conversationArr = allConversations[currentConversationUser];
  // for (const item of data.items) {
  //   if (!conversationArr.includes(item)) {
  //     conversationArr.push(item);
  //   }
  // }
  // conversationArr.sort((a: any, b: any) => {
  //   if (a?.createdAt && b?.createdAt) {
  //     // the latest message first
  //     return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  //   }
  //   return 0;
  // });
  // allConversations[currentConversationUser] = conversationArr;

  return allConversationsByIdData;
};

const allConversationsSlice = createSlice({
  name: "allConversations",
  initialState,
  reducers: {
    setAllConversations: (state, action: PayloadAction<any>) => {
      state.allConversations = action.payload?.data;
    },
    setAllConversationsById: (state, action: PayloadAction<any>) => {
      state.allConversationsById = getAllConversationsById(state, action.payload?.data);
    },
    clearAllConversations: (state) => {
      state.allConversations = [];
    },
    clearAllConversationsById: (state) => {
      state.allConversationsById = null;
    },
  },
});

export const {
  setAllConversations,
  setAllConversationsById,
  clearAllConversations,
  clearAllConversationsById,
} = allConversationsSlice.actions;
export const AllConversationsReducer = allConversationsSlice.reducer;
