import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  readStatus: null,
};

const readMessageStatusSlice = createSlice({
  name: "readMessageStatus",
  initialState,
  reducers: {
    setReadMessageStatus: (state, action: PayloadAction<any>) => {
      state.readStatus = action.payload;
    },
    clearReadMessageStatus: (state) => {
      state.readStatus = null;
    },
  },
});

export const {
  setReadMessageStatus,
  clearReadMessageStatus
} = readMessageStatusSlice.actions;
export const ReadMessageStatusReducer = readMessageStatusSlice.reducer;
