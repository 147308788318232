import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  isLoading: false,
};

const currentContactUserSlice = createSlice({
  name: "currentContactUser",
  initialState,
  reducers: {
    setContactUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
      state.isLoading = false;
    },
    setIsSelectingContactUser: (state, action: PayloadAction<any>) => {
      state.isLoading = action?.payload;
    },
    clearContactUser: (state) => {
      state.user = null;
      state.isLoading = false;
    },
  },
});

export const { setContactUser, setIsSelectingContactUser, clearContactUser } =
  currentContactUserSlice.actions;
export const ContactUserReducer = currentContactUserSlice.reducer;
