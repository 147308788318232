import { ConfigProvider } from "antd";
import React, { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Toaster } from "react-hot-toast";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorScreen } from "./components/error-screen";
import i18n from "./i18n";
import { Router } from "./routes";
import reduxStore from "./store";
import Colors from "./themes/colors";
import patchDOMForGoogleTranslate from "./utils/patchDOMForGoogleTranslate";

export const { store, persistor } = reduxStore();

patchDOMForGoogleTranslate();

const App = () => (
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18n}>
            <Suspense fallback="loading">
              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: Colors.Secondary,
                    borderRadius: 2,
                  },
                }}
              >
                <Toaster />
                <Router />
              </ConfigProvider>
            </Suspense>
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

export default App;
