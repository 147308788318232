import dayjs from "dayjs";
export const formattedTime = (timestamp: string, second?: boolean) => {
  const date = new Date(timestamp);

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  };

  if (!second) {
    timeOptions["second"] = "numeric";
  }

  const formatter = new Intl.DateTimeFormat(undefined, timeOptions);
  const timeZone = formatter.resolvedOptions().timeZone;

  const formattedTime = new Intl.DateTimeFormat(undefined, {
    ...timeOptions,
    timeZoneName: undefined,
    timeZone,
  }).format(date);

  return formattedTime;
};

export const returnElipsesText = (text: string) => {
  let resultString = "";
  let length = 0;
  let extractedString = text?.slice(-5);
  length = text.length;
  let ellipsis = "...";
  resultString =
    extractedString.slice(0, 5) + ellipsis + text.slice(length - 3);

  return resultString;
};

export function calculateTimeDifferenceInAMPM(date1: any, date2: any) {
  const parsedDate1 = dayjs(date1);
  const parsedDate2 = dayjs(date2);
  const minFrom = parsedDate1.minute();
  const minTo = parsedDate2.minute();
  const hourFrom = parsedDate1.hour();
  const hourTo = parsedDate2.hour();

  const ft = dayjs(`2000-01-01 ${hourFrom}:${minFrom}`);
  const tt = dayjs(`2000-01-01 ${hourTo}:${minTo}`);

  const mins = tt.diff(ft, "minutes", true);
  const totalHours = mins / 60;

  if (
    (totalHours.toFixed() === "24" && minTo === 59) ||
    (totalHours.toFixed() === "-24" && minFrom === 59)
  ) {
    return true;
  }

  return false;
}

export const dateFormat = (date: any) => {
  const newDate = new Date(date.getTime());
  newDate.setUTCDate(newDate.getUTCDate() + 1);

  return newDate.toISOString().split("T")[0];
};

export const dateToUtc = (date: any) => {
  const newDate = new Date(date.getTime());
  newDate.setUTCDate(newDate.getUTCDate() + 1);

  return newDate.toISOString().split("T")[0];
};

export function formatTimeToAMPM(dateTimeString: string) {
  var [hours, minutes] = dateTimeString.split(":").map(Number);

  var period = hours < 12 ? "AM" : "PM";

  hours = hours % 12;
  if (hours === 0) {
    hours = 12;
  }

  let Min = minutes < 10 ? `0${minutes}` : minutes;
  let Hour = hours < 10 ? `0${hours}` : hours;

  return `${Hour}:${Min} ${period}`;
}

export function capitalize(str: string) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } else {
    return "";
  }
}

export const isValidFile = (file: string) => {
  if (!file || file.length === 0) {
    return false;
  }

  if (
    file.endsWith(".png") ||
    file.endsWith(".jpg") ||
    file.endsWith(".svg") ||
    file.endsWith(".webp") ||
    file.endsWith(".jpeg") ||
    file.endsWith(".jpe") ||
    file.endsWith(".jif") ||
    file.endsWith(".jfif") ||
    file.endsWith(".jfi") ||
    file.endsWith(".svgz")
  ) {
    return true;
  }

  return false;
};
