import { createSelector } from "reselect";

const allConversationsSelector = (state: TReduxState) =>
  state.features.allConversations;

export const getAllConversationsStatus = createSelector(
  allConversationsSelector,
  (allConversations) => allConversations.allConversations
);

export const getAllConversationsByIdStatus = createSelector(
  allConversationsSelector,
  (allConversations) => allConversations.allConversationsById
);
