import { Button } from 'antd';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import styles from "./error.module.scss";

export const ErrorScreen: React.ComponentType<FallbackProps> = ({
  error,
  resetErrorBoundary
}) => {
  const { t } = useTranslation();

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div className={styles.error_container}>
      <h2>{ t('error.title') }</h2>
      {error?.message && <p>Error: {error.message}</p>}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Button onClick={resetErrorBoundary}>Try again</Button>
        <Button onClick={refreshPage}>Refresh</Button>
      </div>
    </div>
  );
};
