export const PAGINATION = {
  page: 1,
  limit: 10,
};

export const MSG_CHAR_LIMIT = 160;
export const MSG_UNIT_LIMIT = 10;

export const maxSize = 5 * 1024 * 1024;

export const PhoneNumberRegex = /^\+?1?\s?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

export const PASSWORDREGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,30}$/;

export const URLREGEX =
  /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;

export const EMAILREGEX =
  /(?<!<a[^>]*>)(?<!:\/\/)([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)(?!<\/a>)/gi;
