import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Col, Dropdown, Menu, Row, Typography, notification } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { PAGINATION } from "src/constants/common";
import { LocalStorageService } from "src/services/local-storage";
import { getLoginData } from "src/store/selectors/features/auth";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getLoggedInUserData } from "src/store/selectors/features/loggedInUser";
import {
  getBaseUrl,
  getPreferenceData,
} from "src/store/selectors/features/preference";
import RequestAppAction from "src/store/slices/appActions";
import { clearLoggedInUser } from "src/store/slices/features/LoggedInUser";
import { clearConversationById } from "src/store/slices/features/conversationsById";
import { setId } from "src/store/slices/features/currentDid";
import { setContactUser } from "src/store/slices/features/currentUserContact";
import { setConversationUser } from "src/store/slices/features/currentUserConversation";
import { clearDidNotification } from "src/store/slices/features/didNotification";
import { clearEvents } from "src/store/slices/features/fetchEvents";
import { clearGroupMessages } from "src/store/slices/features/groupMessages";
import { clearGroupData } from "src/store/slices/features/groups";
import { clearNotes } from "src/store/slices/features/notes";
import { clearPlan } from "src/store/slices/features/plan";
import { clearScheduleMessage } from "src/store/slices/features/scheduleMessages";
import { selectGroup } from "src/store/slices/features/selectedGroup";
import { clearUser } from "src/store/slices/features/user";
import Colors from "src/themes/colors";
import useSound from "use-sound";
import mySound from "../../assets/sound/sound.mp3";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { clearNewMessageNotification, setNewMessageNotification } from "src/store/slices/features/newMessageNotification";
import { getNewMessageNotificationData } from "src/store/selectors/features/newMessageNotification";
import { setReadMessageStatus } from "src/store/slices/features/readMessageStatus";
import { getTemplateData } from "src/store/selectors/features/template";

const { Text } = Typography;
interface HeaderProps {
  onClick?: () => void;
  onLogout?: () => void;
  user?: string;
  dids?: any;
}

let socketDid: any;
let userId: any;
// let audioNotification: boolean;
let allDids: any;

const localStorageService = new LocalStorageService();

const Header = ({ onLogout, user, dids }: HeaderProps) => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoginData);
  const selectedUser = useSelector(getLoginData);
  const currentUser: any = useSelector(getLoggedInUserData);
  const did = useSelector(getCurrentDidId);
  const preferencesData = useSelector(getPreferenceData);
  const baseUrl = useSelector(getBaseUrl);
  const notificationData: any = useSelector(getNewMessageNotificationData);
  const templates: any = useSelector(getTemplateData);

  const [preferences, setPreferences] = useState<any>(null);
  const [currentDid, setCurrentDid] = useState<any>("");
  const [token, setToken] = useState<string | null>(null);
  const [otherDids, setOtherDids] = useState<{ data: number; did: number }[]>(
    []
  );
  const [play] = useSound(mySound, {
    interrupt: true,
    soundEnabled: true,
  });
  const [playing, setPlaying] = useState(false);
  const [groupSound, setGroupSound] = useState(false);

  const menu = () => (
    <Menu>
      {Array.isArray(dids?.dids)
        ? dids?.dids
          ?.filter((item: any) => item.did !== currentDid?.did)
          .map((item: any, index: number) => {
            const number = otherDids?.filter((i) => i?.did === item?.id);

            return (
              <div key={index}>
                <Menu.Item
                  onClick={() => {
                    setCurrentDid(item);
                    dispatch(setId(item.id));
                    dispatch(selectGroup(null));
                    dispatch(clearGroupMessages());
                    dispatch(clearGroupData());
                    dispatch(clearConversationById());
                    dispatch(clearNotes());
                    dispatch(setContactUser(null));
                    dispatch(clearEvents());
                    dispatch(setConversationUser(null));
                    dispatch(clearScheduleMessage());
                    dispatch(clearUser());
                    dispatch(clearPlan());
                    if (number[0]?.did === item.id) {
                      dispatch(clearDidNotification());
                      setOtherDids([]);
                    }
                  }}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {item.did}{" "}
                    {number[0]?.data > 0 ? (
                      <div
                        style={{
                          background: Colors.Secondary,
                          height: "0.5rem",
                          width: "0.5rem",
                          marginLeft: "0.5rem",
                          borderRadius: 100,
                          color: Colors.white,
                          fontSize: "0.65rem",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </Menu.Item>
              </div>
            );
          }) : <></>}
    </Menu>
  );

  // audioNotification = preferences?.audioNotification;

  useEffect(() => {
    if (Array.isArray(dids?.dids)) {
      if (!currentDid) {
        setCurrentDid(dids?.dids[0]);
        dispatch(setId(dids?.dids[0].id));
      }

      const meta: any = { ...PAGINATION };

      dispatch(
        RequestAppAction.fetchGroupList({
          id: dids?.dids[0]?.id,
          meta: meta,
        })
      );
    }
  }, [dids]);

  useEffect(() => {
    if (!user) {
      return;
    }

    localStorageService.fetch("preferences").then((res) => {
      let data = "";
      if (res) {
        data = JSON.parse(res);
      }

      if (preferencesData || data) {
        setPreferences(preferencesData ?? data);
        if (!data) {
          localStorageService.persist(
            "preferences",
            preferencesData
          );
        }
      } else {
        dispatch(RequestAppAction.fetchPreference());
      }
    });
  }, [user]);

  useEffect(() => {
    if (!isLoggedIn) {
      setCurrentDid("");
      dispatch(setId(0));
      dispatch(clearLoggedInUser());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn && selectedUser) {
      dispatch(RequestAppAction.getLoggedInUser());
    }
  }, [selectedUser]);

  const getToken = async () => {
    const authToken = await localStorageService.fetch("authToken");

    if (authToken && token !== authToken) {
      setToken(authToken);
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  socketDid = did;
  userId = currentUser?.id;
  allDids = dids?.dids || [];

  useEffect(() => {
    if (!token) {
      return;
    }

    const socket = io(`${baseUrl}events?Authorization=Bearer ${token}`, {
      transports: ["websocket"],
    });

    const handleNewMessage = (res: any) => {
      // console.log(res);
      dispatch(setNewMessageNotification(res));
    };

    const handleReadStatusChanged = (res: any) => {
      // console.log(res);
      dispatch(setReadMessageStatus(res));
    };

    socket.on("newMessage", handleNewMessage);
    socket.on("readStatusChanged", handleReadStatusChanged);

    return () => {
      console.log("SOCKET OFF")
      socket.off("newMessage", handleNewMessage);
      socket.off("readStatusChanged", handleReadStatusChanged);
      socket.disconnect();
    };
  }, [token, baseUrl, dispatch]);

  useEffect(() => {
    if (!token) {
      return;
    }

    const messageNotificationData = notificationData;
    dispatch(clearNewMessageNotification());

    let notSelectedDids = Array.isArray(allDids) ? allDids?.filter(
      (i: { id: string }) => i.id !== socketDid
    ) : [];

    if (notSelectedDids?.length > 0) {
      notSelectedDids?.map((i: { id: string }) => {
        dispatch(
          RequestAppAction.fetchDidNotification({
            id: i?.id,
            meta: "",
            cbSuccess: (res: { data: number; did: number }) => {
              if (
                !otherDids?.some(
                  (e: { did: string | number }) => e?.did === i?.id
                )
              ) {
                let temp = [...otherDids];

                temp.push(res);
                setOtherDids(temp);
              }
            },
            cbFailure: (e: string) => {
              notification.error({
                message: e ?? t("notification.fetchDidNotificationFail"),
              });
            },
          })
        );
      });
    }

    if (!notificationData) {
      return;
    }

    if (messageNotificationData) {
      if (Object.keys(messageNotificationData).includes("groupId")) {
        if (messageNotificationData.userId !== userId) {
          notification.info({ message: t("message.group") });
          dispatch(
            RequestAppAction.fetchGroupList({
              id: socketDid,
              meta: "",
            })
          );
          if (preferences?.audioNotification) {
            setGroupSound(true);
          }
        }
      } else {
        if (messageNotificationData.userId === userId) {
          // dispatch(
          //   RequestAppAction.fetchConversations({
          //     id: socketDid,
          //     meta: "",
          //   })
          // );
          notification.info({ message: t("message.user") });
          if (preferences?.audioNotification) {
            setPlaying(true);
          }
        }
      }
    }
  }, [notificationData, allDids, socketDid]);

  useEffect(() => {
    if (!did) {
      return;
    }

    if (
      templates
      && templates.length > 0
      && templates[0]?.didId === did
    ) {
      return;
    }

    dispatch(
      RequestAppAction.fetchTemplate({
        id: did,
        meta: PAGINATION,
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.getTemplateFail")
          });
        },
      })
    );
  }, [did]);

  useEffect(() => {
    if ((playing || groupSound) && user) {
      const context = new AudioContext();
      context.resume().then(() => {
        play();
      });
      setGroupSound(false);
      setPlaying(false);
    }
  }, [playing, groupSound]);

  return (
    <Row
      style={{
        backgroundColor: "#1C4DA1",
        width: "100%",
        height: "4rem",
        position: "sticky",
        display: "flex",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <Col
        md={{ flex: '30%' }}
        lg={{ flex: '20%' }}
        style={{ padding: "0 0 0 1rem" }}
        className="d-flex align-items-center"
      >
        <img
          alt="Textify Logo"
          style={{ height: "2.8rem" }}
          src={require("../../assets/images/textmeanywhere.png")}
        />
      </Col>
      {user && (
        <>
          {width > 400 && (
            <Col
              md={{ flex: '40%' }}
              lg={{ flex: '60%' }}
              className="d-flex algin-items-center justify-content-center"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                height: "4rem",
              }}
            >
              {currentUser?.companies ? (
                <Text
                  className="font-size-18 font-weight-800 text-white"
                  style={{ textAlign: "center" }}
                >
                  {currentUser?.companies?.name}
                </Text>
              ) : (
                <></>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <UserOutlined
                  style={{
                    color: "white",
                    fontSize: "1.25rem",
                    cursor: "pointer",
                    marginRight: "0.5rem",
                  }}
                />

                <Text className="font-size-18 font-weight-800 text-white pr-5">
                  {user}
                </Text>
                <Dropdown
                  dropdownRender={menu}
                  disabled={dids?.dids?.length < 2}
                  placement="bottomLeft"
                  className=" pl-5 bg-primary text-white"
                >
                  <div>
                    <span>
                      {currentDid?.did}{" "}
                      {dids?.dids?.length > 1 ? <DownOutlined /> : <></>}
                    </span>
                  </div>
                </Dropdown>
              </div>
            </Col>
          )}
          <Col
            md={{ flex: '30%' }}
            lg={{ flex: '20%' }}
            className="d-flex justify-content-end align-items-center"
            style={{
              color: "white",
              padding: "0 2rem",
              margin: 0,
              height: "4rem",
            }}
          >
            <Text
              style={{
                fontSize: "1.12rem",
                cursor: "pointer",
                color: "white",
              }}
              onClick={onLogout}
            >
              {t("common.logout")}
            </Text>
          </Col>
        </>
      )}
    </Row>
  );
};
export default Header;
