/* eslint-disable */

// This is a workaround provided by a member of the React Core team
// that monkey patches removeChild and insertBefore to fail when React tries to remove a TextNode.
// This prevents the crash, but doesn't solve the underlying issue.

// See https://github.com/facebook/react/issues/11538#issuecomment-417504600
// https://martijnhols.nl/blog/everything-about-google-translate-crashing-react
// 

export default function patchDOMForGoogleTranslate() {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;

    // @ts-ignore: Unreachable code error
    Node.prototype.removeChild = function(child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // @ts-ignore: Unreachable code error
      return originalRemoveChild.apply(this, arguments);
    }

    const originalInsertBefore = Node.prototype.insertBefore;

    // @ts-ignore: Unreachable code error
    Node.prototype.insertBefore = function(newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
        }
        return newNode;
      }

      // @ts-ignore: Unreachable code error
      return originalInsertBefore.apply(this, arguments);
    }
  }
}
