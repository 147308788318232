import { createSelector } from "reselect";

const conversationByIdSelector = (state: TReduxState) =>
  state.features.conversationsById;

export const getBaseUrl = createSelector(
  conversationByIdSelector,
  (app) => app.baseUrl
);

export const getConversationByIdData = createSelector(
  conversationByIdSelector,
  (app) => app.apiStatus.data
);

export const getIsConversationByIdNewFetch = createSelector(
  conversationByIdSelector,
  (app) => app.apiStatus.isNewFetch
);

export const getConversationByIdState = createSelector(
  conversationByIdSelector,
  (app) => app.conversation
);

export const getConversationByIdLoading = createSelector(
  getConversationByIdState,
  (states) => states?.isLoading
);
