import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  conversation: {
    isLoading: false,
    error: null,
  },
  apiStatus: {
    statusCode: null,
    statusText: null,
    data: null,
    isNewFetch: false,
  },
};

export const conversationSlice = createSlice({
  name: "conversation",
  initialState: INITIAL_STATE,
  reducers: {
    setConversationData: (state, action: PayloadAction<any>) => ({
      ...state,
      conversation: {
        ...state.conversation,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
        isNewFetch: false,
      }
    }),
    fetchConversationData: (state) => {
      state.conversation.isLoading = true;
    },
    fetchConversationSuccess: (state, action: PayloadAction<any>) => ({
      ...state,
      conversation: {
        ...state.conversation,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        data: action.payload?.data,
        isNewFetch: true,
      },
    }),
    fetchConversationFailure: (state, action: PayloadAction<any>) => ({
      ...state,
      conversation: {
        ...state.conversation,
        error: action.payload?.message,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: action.payload?.statusCode,
        statusText: action.payload?.statusText,
        isNewFetch: true,
      },
    }),
    clearConversationById: (state) => ({
      ...state,
      conversation: {
        ...state.conversation,
        error: null,
        isLoading: false,
      },
      apiStatus: {
        ...state.apiStatus,
        statusCode: null,
        statusText: null,
        data: null,
        isNewFetch: false,
      },
    }),
  },
});

export const {
  setConversationData,
  fetchConversationData,
  fetchConversationFailure,
  fetchConversationSuccess,
  clearConversationById,
} = conversationSlice.actions;
export const conversationReducer = conversationSlice.reducer;
