import { Card, Flex, Space, Spin, Typography, notification } from "antd";
import dayjs from "dayjs";
import React, { useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPlanData } from "src/store/selectors/features/plans";
import RequestAppAction from "src/store/slices/appActions";
import "./plan.scss";

const { Title, Text } = Typography;

const Plan: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const plansData: any = useSelector(getPlanData);
  const dispath = useDispatch();

  useLayoutEffect(() => {
    setIsLoading(true);
    dispath(
      RequestAppAction.fetchPlans({
        cbSuccess: () => {
          setIsLoading(false);
        },
        cbFailure: (e: string) => {
          setIsLoading(false);
          notification.error({
            message: e ?? t("notification.fetchPlansFail")
          });
        },
      })
    );
  }, []);

  return (
    <div className="plan-page">
      <Spin
        spinning={isLoading}
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      />
      <Card className="plan-card card-shadow">
        <Flex
          vertical={true}
          justify="center"
          align="center"
          gap={30}
          className="plan-card-container"
        >
          <Title level={3} className="font-size-20 font-weight-600">
            {t("plan.monthlyUsageAndPlan")}
          </Title>
          <Space
            direction="horizontal"
            size={30}
            className="plan-inner-card-space"
          >
            <Space direction="vertical" size={10} className="plan-inner-card">
              <Title level={4} className="font-size-18 font-weight-600">
                {t("plan.usage")}
              </Title>
              <div className="plan-inner-card-body card-shadow">
                <Space direction="vertical" size={14}>
                  <Title
                    level={4}
                    className="font-size-18 pb-5 font-weight-600"
                  >
                    {t("plan.monthOf", {
                      month: dayjs().format("MMMM"),
                      year: dayjs().format("YYYY"),
                    })}
                  </Title>
                  <Text className="font-size-14 font-weight-300">
                    {t("plan.currentUsageIs", {
                      date: dayjs().format("DD/MM/YYYY"),
                      limit: plansData?.usage?.currentUnits || 0,
                    })}
                  </Text>
                  <Text className="font-size-14 font-weight-800">
                    {t("plan.smsUnits", {
                      limit: plansData?.usage?.currentUnits || 0,
                      units: plansData?.usage?.limit || 0,
                    })}
                  </Text>
                </Space>
              </div>
            </Space>
            <Space direction="vertical" size={10} className="plan-inner-card">
              <Title level={4} className="font-size-18 font-weight-600">
                {t("plan.overage")}
              </Title>
              <div className="plan-inner-card-body card-shadow">
                <Space direction="vertical" size={14}>
                  <Title
                    level={4}
                    className="font-size-18 pb-5 font-weight-600"
                  >
                    {t("plan.monthOf", {
                      month: dayjs().format("MMMM"),
                      year: dayjs().format("YYYY"),
                    })}
                  </Title>

                  <Text className="font-size-14 font-weight-800">
                    {t("plan.youAreSmsUnits", {
                      sms: plansData?.overage?.currentUnits || 0,
                    })}
                    <br /> {t("plan.overYourPlan")}
                  </Text>
                  <Text className="font-size-14 font-weight-300">
                    {t("plan.AsOfYouOverYourPlan", {
                      date: dayjs().format("DD/MM/YYYY"),
                      price: plansData?.overage?.currentCost?.toFixed(2),
                    })}
                  </Text>
                </Space>
              </div>
            </Space>
            <Space direction="vertical" size={10} className="plan-inner-card">
              <Title level={4} className="font-size-18 font-weight-600">
                {t("plan.currentPlan")}
              </Title>
              <div
                style={{ padding: "1.8rem" }}
                className="plan-inner-card-body card-shadow"
              >
                <Space direction="vertical" size={10} className="pb-5">
                  <Title
                    level={4}
                    className="font-size-18 pb-5 font-weight-600"
                  >
                    {t("plan.planType", {
                      type: plansData?.plan?.subscriptionPlan,
                    })}
                  </Title>
                  {/* <Text className="font-size-14 font-weight-300">
                    {t("plan.dollarPerMonth", {
                      dollar: plansData?.plan?.monthlyRate || 0,
                    })}
                  </Text> */}
                  <Text className="font-size-14 font-weight-300">
                    {t("plan.inboundSmsUnits", {
                      limit: plansData?.plan?.inboundUnits || 0,
                    })}
                  </Text>
                  <Text className="font-size-14 font-weight-300">
                    {t("plan.outboundSmsUnits", {
                      limit: plansData?.plan?.outboundUnits || 0,
                    })}
                  </Text>
                  <Text className="font-size-14 font-weight-300">
                    {t("plan.overageCostCentPerUnit", {
                      cost: plansData?.plan?.overageCost || 0,
                    })}
                  </Text>
                </Space>
              </div>
            </Space>
          </Space>
          {/* <Text className="font-size-14 font-weight-800">
            {t("plan.currentChargedFor", {
              month: dayjs().format("MMMM"),
              year: dayjs().format("YYYY"),
              price: plansData?.totalCost?.toFixed(2),
            })}
          </Text> */}
        </Flex>
      </Card>
    </div>
  );
};

export default Plan;
