import { Menu } from "antd";
import { IAddVariable } from "src/constants/types";
import "./add-variable.scss";

const AddVariableList = ({
  variableList,
  form,
  textareaRef,
  message,
  onTextChange,
}: {
  variableList: IAddVariable[];
  form: any;
  textareaRef: any;
  message: string;
  onTextChange: (value: string) => void;
}) => {
  const addVariableAtCursor = (variable: string) => {
    if (!textareaRef?.current) return;

    const textarea = textareaRef.current.resizableTextArea.textArea;
    let { selectionStart, selectionEnd } = textarea;

    if (!message) {
      message = form.getFieldValue("content") ?? "";
      selectionStart = message.length;
      selectionEnd = message.length;
    }

    const beforeCursorPosition = message.substring(0, selectionStart);
    const afterCursorPosition = message.substring(selectionEnd);

    const newValue = `${beforeCursorPosition}${variable}${afterCursorPosition}`;
    onTextChange(newValue);

    const handler = setTimeout(() => {
      form.setFieldsValue({ content: newValue });
      textarea.setSelectionRange(
        selectionStart + variable.length, selectionStart + variable.length
      );
      textarea.focus();
    }, 0);

    return () => {
      clearTimeout(handler);
    };
  };

  if (!variableList || variableList.length === 0) {
    return <></>;
  }

  return (
    <Menu className="add-variable-list">
      {variableList.map((item: any, index: number) => (
        <Menu.Item
          key={`add-variable-list-${index}`}
          className="variable-list-item"
          onClick={() => addVariableAtCursor(item.placeholder)}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default AddVariableList;
