import { LockFilled } from "@ant-design/icons";
import { Form, Input, Space, notification } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "src/components/button";
import Header from "src/components/header";
import { PASSWORDREGEX } from "src/constants/common";
import RequestAppAction from "src/store/slices/appActions";
import styles from "./resetPassword.module.scss";

interface state {
  token: string | null;
  userId: string | null;
}

const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [form] = useForm();
  const dispatch = useDispatch();
  const [state, setState] = useState<state>({
    token: "",
    userId: "",
  });

  const onFinish = (val: { confirmPassword: string }) => {
    const param = {
      password: val?.confirmPassword?.trim(),
      token: state?.token,
    };

    dispatch(
      RequestAppAction.resetPassword({
        userId: state?.userId,
        data: param,
        cbSuccess: () => {
          notification.success({ message: "Success" });
          setTimeout(() => {
            navigate("/login");
          }, 2000);
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.resetPasswordFail")
          });
        },
      })
    );
  };

  const verify = () => {
    const url = window.location.pathname;
    const userIdMatch = url.match(/userId=([^&]+)/);
    const tokenMatch = url.match(/token=([^&]+)/);
    const userId = userIdMatch ? userIdMatch[1] : null;
    const token = tokenMatch ? tokenMatch[1] : null;

    dispatch(
      RequestAppAction.verifyToken({
        userId: userId,
        token: token,
        cbFailure: (e: String) => {
          notification.error({
            message: e ?? t("notification.verifyTokenFail")
          });
          navigate("/token-expired");
        },
      })
    );

    setState({ userId: userId, token: token });
  };

  useEffect(() => {
    verify();
  }, []);

  const comparePasswords = () => {
    const debounceTimeout = setTimeout(() => {
      const val = form.getFieldValue("newPassword");
      const confirmPass = form.getFieldValue("confirmPassword");

      if (!PASSWORDREGEX.test(val) && val?.length > 0) {
        form.setFields([
          {
            name: "confirmPassword",
            errors: [t("message.passwordRegexError")],
          },
        ]);
      } else {
        form.setFields([
          {
            name: "confirmPassword",
            errors: undefined,
          },
        ]);
      }

      if (val !== confirmPass && confirmPass?.length > 0) {
        form.setFields([
          {
            name: "confirmPassword",
            errors: [t("fields.passwordDoNotMatchError")],
          },
        ]);
      }

      if (val === confirmPass && confirmPass?.length > 0) {
        form.setFields([
          {
            name: "confirmPassword",
            errors: undefined,
          },
        ]);
      }
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  };

  return (
    <div className={styles.login_form_container}>
      <Space
        onClick={() => navigate("/")}
        align="center"
        className={styles.login_header}
      >
        <Header />
      </Space>
      <Space direction="vertical" className={styles.login_form_wrapper}>
        <Form
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
          autoComplete="off"
          className={`login-form ${styles.login_form}`}
        >
          <Form.Item
            name="newPassword"
            label={t("fields.newPassword")}
            rules={[{ required: true, message: t("fields.newPasswordError") }]}
          >
            <Input.Password
              prefix={<LockFilled className={styles.site_form_item_icon} />}
              placeholder={t("fields.password")}
              className={styles.login_inputs}
              onChange={comparePasswords}
            />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={t("fields.confirmPassword")}
            className="mb-5"
            rules={[
              { required: true, message: t("fields.confirmPasswordError") },
            ]}
          >
            <Input.Password
              onChange={comparePasswords}
              prefix={<LockFilled className={styles.site_form_item_icon} />}
              placeholder={t("fields.password")}
              className={styles.login_inputs}
            />
          </Form.Item>
          <Form.Item style={{ marginTop: "3.9rem" }}>
            <Button
              title={t("button.resetMyPassword")}
              buttonType="submit"
              btnClass="full_btn"
            />
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export default ResetPassword;
