import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { getBaseUrl } from "../selectors/features/app";
import {
  fetchConversationFailure,
  fetchConversationSuccess,
} from "../slices/features/conversationsById";
import { RequestTypes } from "../types";

const appservice = new AppService();

function* fetchConversationSaga(action: any) {
  const { payload } = action;
  const { id, meta } = payload;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appservice.fetchConversationsById,
      baseUrl,
      id,
      meta
    );

    yield put(fetchConversationSuccess({ ...response, meta }));

    payload?.cbSuccess && payload?.cbSuccess({ ...response });
  } catch (error: any) {
    const { statusCode, statusText } = error;

    const message = error?.data?.errors?.message;

    payload?.cbFailure && payload?.cbFailure(message);

    yield put(fetchConversationFailure({ statusCode, statusText }));
  }
}

export function* watchfetchConversationById() {
  yield takeLatest(
    RequestTypes.GET_CONVERSATIONS_ID_REQUEST,
    fetchConversationSaga
  );
}
