import { CloseSquareOutlined } from "@ant-design/icons";
import { Checkbox, Col, Form, Row, Space, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { LocalStorageService } from "src/services/local-storage";
import { getLoginData } from "src/store/selectors/features/auth";
import { getCurrentDidId } from "src/store/selectors/features/currentDid";
import { getDidData } from "src/store/selectors/features/dids";
import RequestAppAction from "src/store/slices/appActions";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import "./index.scss";

const { Title, Text } = Typography;

const localStorageService = new LocalStorageService();

const AutoResponder: React.FC = () => {
  const { t } = useTranslation();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const currentDid = useSelector(getCurrentDidId);
  const dids: any = useSelector(getDidData);
  const [dontShow, setDontShow] = useState(false);
  const [message, setMessage] = useState(false);
  const [edit, setEdit] = useState<number | null>(null);
  const user = useSelector(getLoginData);

  const [values, setValues] = useState({
    autoResponder: false,
    autoRespondOncePerContact: false,
  });

  const onChangeAutoResponder = (val: boolean) => {
    const param = {
      autoResponder: val,
      autoRespondOncePerContact: values.autoRespondOncePerContact,
    };
    dispatch(
      RequestAppAction.putAutoResponder({
        id: currentDid,
        data: param,
        cbSuccess: () => {
          setValues((pre: any) => ({ ...pre, autoResponder: val }));
          dispatch(
            RequestAppAction.fetchDid({ cbSuccess: () => setEdit(null) })
          );
        },
      })
    );
  };

  useEffect(() => {
    if (dids && dids?.dids) {
      dids?.dids?.map((i: any) => {
        if (i?.id === currentDid) {
          setValues({
            autoResponder: i?.autoResponderOn,
            autoRespondOncePerContact: i.autoRespondOncePerContact,
          });
        }
      });
    }
  }, [dids, currentDid]);

  const onChangeAutoResponderRate = (val: boolean) => {
    const param = {
      autoResponder: values.autoResponder,
      autoRespondOncePerContact: val,
    };

    dispatch(
      RequestAppAction.putAutoResponder({
        id: currentDid,
        data: param,
        cbSuccess: () => {
          setValues((pre: any) => ({ ...pre, autoRespondOncePerContact: val }));
          dispatch(
            RequestAppAction.fetchDid({ cbSuccess: () => setEdit(null) })
          );
        },
      })
    );
  };

  const onPressClose = async () => {
    setMessage(false);
    if (dontShow) {
      await localStorageService.persist(
        "responderMessage",
        user?.userId?.toString()
      );
    }
  };

  const checkMessage = async () => {
    const isRemoved = await localStorageService.fetch("responderMessage");

    if (isRemoved === user?.userId?.toString()) {
      setMessage(false);
    } else {
      setMessage(true);
    }
  };

  useEffect(() => {
    checkMessage();
  }, []);

  return (
    <Row
      className="d-flex justify-content-center"
      style={{ marginBottom: "1rem" }}
    >
      {message && (
        <Col
          style={{ paddingBottom: "0rem" }}
          className="auto-responder-sms-box"
        >
          <div>
            <Space direction="horizontal" className="sms-box">
              <Text className="font-size-12 font-weight-300">
                {t("common.smsContent")}
              </Text>
              <CloseSquareOutlined
                onClick={onPressClose}
                size={80}
                type="primary"
              />
            </Space>
            <Space className="pt-4 font-weight-400 font-size-13">
              {t("common.checkBoxContent")}
              <Checkbox
                checked={dontShow}
                onChange={() => {
                  setDontShow(!dontShow);
                }}
                className="pl-3"
              />
            </Space>
          </div>
        </Col>
      )}
      <Col className="auto-responder">
        <Form name="switch">
          <Space
            style={{ alignItems: "center" }}
            direction="vertical"
            size={4}
            className="auto-card-1"
          >
            <div className="text-center">
              <Title level={4}>{t("common.autoresponder")}</Title>
            </div>
            <div
              style={{ width: width > 576 ? "25rem" : "90%" }}
              className="d-flex align-items-center justify-content-between pl-4 pb-2"
            >
              <Text className="font-weight-400 font-size-13">
                {t("common.autoresponderMainSetting")}
              </Text>

              <Switch
                checked={values.autoResponder}
                onClick={() => {
                  setEdit(1);
                }}
                onChange={(e) => {
                  onChangeAutoResponder(e);
                }}
                loading={edit === 1}
                checkedChildren={t("switch.on")}
                unCheckedChildren={t("switch.off")}
                style={{ minWidth: "50px" }}
              />
            </div>
            <div
              style={{ width: width > 576 ? "25rem" : "90%" }}
              className="d-flex align-items-center justify-content-between  pl-4"
            >
              <Text className="font-weight-400 font-size-13">
                {t("common.respondOncePerDayPerContact")}
              </Text>

              <Switch
                loading={edit === 2}
                checked={values.autoRespondOncePerContact}
                onClick={() => {
                  setEdit(2);
                }}
                onChange={(e) => {
                  onChangeAutoResponderRate(e);
                }}
                checkedChildren={t("switch.on")}
                unCheckedChildren={t("switch.off")}
                style={{ minWidth: "50px" }}
              />
            </div>
          </Space>
        </Form>
      </Col>
    </Row>
  );
};

export default AutoResponder;
