import { SendOutlined } from "@ant-design/icons";
import { Button, Spin, Tooltip } from "antd";

interface ISendButton {
  isDisabled?: boolean;
  isLoading?: boolean;
  btnIconStyle?: any;
  btnStyle?: any;
  btnHtmlType?: "button" | "submit" | "reset" | undefined;
  onBtnClick?: (value: any) => void;
  tooltipTitle?: string;
}

const SendButton = ({
  isDisabled = true,
  isLoading = false,
  btnIconStyle = { fontSize: "1.5rem", color: "#000" },
  btnStyle = { border: "none", background: "transparent", marginLeft: 10 },
  btnHtmlType = "submit",
  onBtnClick,
  tooltipTitle = "",
}: ISendButton) => {
  return (tooltipTitle && tooltipTitle.length > 0) ? (
    <Tooltip
      trigger={"hover"}
      color="red"
      title={tooltipTitle}
    >
      <Button
        htmlType={btnHtmlType}
        type="text"
        disabled={isDisabled}
        icon={
          isLoading ? (
            <Spin />
          ) : (
            <SendOutlined style={btnIconStyle} />
          )
        }
        style={btnStyle}
      />
    </Tooltip>
  ) : (
    <Button
      htmlType={btnHtmlType}
      type="text"
      disabled={isDisabled}
      icon={
        isLoading ? (
          <Spin />
        ) : (
          <SendOutlined style={btnIconStyle} />
        )
      }
      style={btnStyle}
      onClick={
        btnHtmlType === "button" && onBtnClick ? onBtnClick : undefined
      }
    />
  );
};

export default SendButton;
