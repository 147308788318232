import { createSelector } from "reselect";

const currentContactUserSelector = (state: TReduxState) =>
  state.features.contactUser;

export const getContactUser = createSelector(
  currentContactUserSelector,
  (currentUser) => currentUser.user
);

export const getIsSelectingContactUser = createSelector(
  currentContactUserSelector,
  (currentUser) => currentUser.isLoading
);
