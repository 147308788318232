import { Form, Input, Space, Spin, Tooltip, Typography } from "antd";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/button";
import { TextifyModal } from "../../../components/modal";
import ModalButtonContainer from "src/components/modal-button-container";
import AddVariableBtn from "src/components/modal-button-container/add-variable/AddVariableBtn";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { firstName } from "src/constants/add-variable";
import { IAddVariable } from "src/constants/types";
import "../setting.scss";

interface ModalProps {
  dId: number | null;
  isEdit: boolean;
  onUpdate: (i: any, it: any) => void;
  onSave: (i: any, it: any) => void;
  form: any;
}

export const AddTemplateModal = forwardRef(
  ({ dId, form, isEdit, onUpdate, onSave }: ModalProps, ref: any) => {
    const { Text } = Typography;
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const textareaRef: any = useRef<any>(null);
    const [units, setUnits] = useState<number>(0);
    const [characters, setCharacters] = useState<number>(0);
    const [totalCharacters, setTotalCharacters] = useState<String>("");
    const [message, setMessage] = useState<string>("");
    const [variableList, setVariableList] = useState<IAddVariable[]>([]);

    useEffect(() => {
      if (isEdit) {
        const content = form.getFieldValue("content");
        setTotalCharacters(content);
        setMessage(content);
      }
    }, [form, isEdit]);

    useEffect(() => {
      const variables: IAddVariable[] = [];
      variables.push(firstName);
      setVariableList(variables);
    }, []);

    useEffect(() => {
      const charactersLength = totalCharacters?.length;
      const totalUnits = Math.floor(charactersLength / 160);
      const chr = charactersLength - (totalUnits || 0) * 160;
      if (charactersLength === 0) {
        setUnits(0);
      } else {
        setUnits(totalUnits + 1);
      }

      setCharacters(chr);
    }, [totalCharacters]);

    const onTextChange = (value: string) => {
      setMessage(value);
      setTotalCharacters(value.trim());
    }

    const handleTextChange = async (e: any) => {
      onTextChange(e.target.value);
    };

    const handleCancel = () => {
      form.resetFields();
      setTotalCharacters("");
      setMessage("");
      setCharacters(0);
      setUnits(0);
      ref?.current?.closeModal();
    };

    return (
      <div style={{ zIndex: 9999 }}>
        <TextifyModal
          ref={ref}
          afterClose={handleCancel}
          className="template-Modal"
        >
          <Space direction="vertical" size={20} style={{ width: "100%" }}>
            <Spin spinning={false}>
              <Form
                onFinish={(values) =>
                  isEdit
                    ? onUpdate(values, form.getFieldValue("id"))
                    : onSave(values, dId)
                }
                form={form}
                name="templateForm"
              >
                <Space direction="vertical" size={12} style={{ width: "100%" }}>
                  <Form.Item
                    name="title"
                    rules={[{ required: true, message: "Please Add Title" }]}
                  >
                    <Input
                      placeholder="Template Name"
                      size="large"
                      type="text"
                    />
                  </Form.Item>
                  <div className="d-flex align-items-center justify-content-right">
                    <Text>
                      {t("sideBar.chars")} {characters}/160
                      <span className="pl-5">
                        {t("sideBar.msgUnits")} {units}/10
                      </span>
                    </Text>
                  </div>
                  <div
                    className="align-items-end pb-5"
                    style={{ position: "relative" }}
                  >
                    <Form.Item
                      style={{ width: "100%" }}
                      name="content"
                      rules={[
                        {
                          required: true,
                          message: "Please Enter Template Content",
                        },
                        {
                          validator: (_, value) => {
                            if (value && value.length > 1600) {
                              return Promise.reject(
                                "Content length cannot exceed 1600 characters"
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.TextArea
                        ref={textareaRef}
                        rows={6}
                        placeholder="Type your text here..."
                        onChange={handleTextChange}
                        style={{ resize: "none" }}
                      />
                    </Form.Item>
                    <ModalButtonContainer>
                      {width > 576 && (
                        <AddVariableBtn
                          disabled={!variableList || variableList.length === 0}
                          variableList={variableList}
                          form={form}
                          textareaRef={textareaRef}
                          message={message}
                          onTextChange={onTextChange}
                          style={{ borderRadius: 50, marginLeft: 10 }}
                        />
                      )}
                    </ModalButtonContainer>
                  </div>
                  <Space
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "2rem",
                    }}
                  >
                    <Button
                      onBtnClick={handleCancel}
                      title={"Cancel"}
                      btnClass="white_btn"
                    />
                    {units > 10 ? (
                      <Tooltip open color="red" title={t("message.unitError")}>
                        <Button
                          disabled={true}
                          title={"Save Changes"}
                          buttonType="button"
                        />
                      </Tooltip>
                    ) : (
                      <Button title={"Save Changes"} buttonType="submit" />
                    )}
                  </Space>
                </Space>
              </Form>
            </Spin>
          </Space>
        </TextifyModal>
      </div>
    );
  }
);
