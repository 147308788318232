import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  notificationData: null,
};

const newMessageNotificationSlice = createSlice({
  name: "newMessageNotification",
  initialState,
  reducers: {
    setNewMessageNotification: (state, action: PayloadAction<any>) => {
      state.notificationData = action.payload;
    },
    clearNewMessageNotification: (state) => {
      state.notificationData = null;
    },
  },
});

export const {
  setNewMessageNotification,
  clearNewMessageNotification
} = newMessageNotificationSlice.actions;
export const NewMessageNotificationReducer = newMessageNotificationSlice.reducer;
