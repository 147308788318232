import {
  DashboardOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu, MenuProps, Spin, Typography, notification } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Header from "src/components/header";
import { getSignoutLoadingState } from "src/store/selectors/features/signout";
import RequestAppAction from "src/store/slices/appActions";

import { Content } from "antd/es/layout/layout";
import { persistor } from "src/App";
import { ROUTES } from "src/constants/navigation-routes";
import DashboardRoutes from "src/routes/dashboard-routes";
import {
  getConversationsData,
  getConversationsLoading,
} from "src/store/selectors/features/conversations";
import { getDidData, getDidLoading } from "src/store/selectors/features/dids";
// import { getGroupsData } from "src/store/selectors/features/groupList";
import { clearDid } from "src/store/slices/features/fetchDids";
import Colors from "src/themes/colors";
import useWindowDimensions from "src/hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";
import "./dashboard.scss";

type MenuItem = Required<MenuProps>["items"][number];

const { Header: AntdHeader, Sider } = Layout;
const { Text, Title } = Typography;

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const { t } = useTranslation();

  const [collapsed, setCollapsed] = useState(false);
  const signoutLoading = useSelector(getSignoutLoadingState);
  const [selectedKey, setSelectedKey] = useState(["1"]);
  const didLoading = useSelector(getDidLoading);
  const didDetails = useSelector(getDidData);
  const conversationsLoading = useSelector(getConversationsLoading);
  // const groups: any = useSelector(getGroupsData);
  const conversationsData: any = useSelector(getConversationsData);
  const [stateOpenKeys, setStateOpenKeys] = useState(["1"]);
  // let groupCount = groups?.meta?.totalUnread;

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[] | null,
    target?: string
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      target,
    } as MenuItem;
  }

  const items = [
    getItem(
      <div
        style={{ display: "flex", alignItems: "center" }}
      >
        Conversations
        {conversationsData?.meta?.totalUnread ? (
          <div style={{ marginLeft: "0.5rem" }}>
            <Text
              style={{
                background: Colors.Secondary,
                padding: "0.1rem 0.4rem 0.1rem 0.4rem",
                borderRadius: 100,
                color: Colors.white,
                fontSize: "0.65rem",
              }}
            >
              {conversationsData.meta.totalUnread}
            </Text>
          </div>
        ) : (
          <></>
        )}
      </div>,
      "1",
      <MessageOutlined />,
      null,
      ROUTES.CONVERSATIONS
    ),
    getItem("Contacts", "2", <UserOutlined />, null, ROUTES.CONTACTS),
    // getItem(
    //   <div
    //     style={{
    //       display: "flex",
    //       alignItems: "center",
    //     }}
    //   >
    //     Groups
    //     {groupCount ? (
    //       <div
    //         style={{
    //           height: "0.4rem",
    //           width: "0.4rem",
    //           borderRadius: 100,
    //           backgroundColor: Colors.Secondary,
    //           marginLeft: "0.5rem",
    //         }}
    //       />
    //     ) : (
    //       <></>
    //     )}
    //   </div>,
    //   "sub1",
    //   <TeamOutlined />,
    //   [
    //     getItem(
    //       <div
    //         style={{
    //           display: "flex",
    //           alignItems: "center",
    //         }}
    //       >
    //         Conversations
    //         {groupCount ? (
    //           <div
    //             style={{
    //               marginLeft: "0.5rem",
    //             }}
    //           >
    //             <Text
    //               style={{
    //                 background: Colors.Secondary,
    //                 padding: "0.1rem 0.4rem 0.1rem 0.4rem",
    //                 borderRadius: 100,
    //                 color: Colors.white,
    //                 fontSize: "0.8rem",
    //               }}
    //             >
    //               {groupCount}
    //             </Text>
    //           </div>
    //         ) : (
    //           <></>
    //         )}
    //       </div>,
    //       "3",
    //       null,
    //       null,
    //       ROUTES.GROUPCONVERSATION
    //     ),
    //     getItem("Contacts", "4", null, null, ROUTES.GROUPCONTACTS),
    //   ]
    // ),
    getItem("Settings", "sub3", <SettingOutlined />, [
      getItem("Password", "5", null, null, ROUTES.PASSWORD),
      getItem("Preferences", "6", null, null, ROUTES.PREFERENCES),
      getItem("Templates", "7", null, null, ROUTES.TEMPLATES),
      getItem("Autoresponder", "sub2", <SettingOutlined />, [
        getItem("Main", "8", null, null, ROUTES.TIME_OF_DAY),
        getItem("Vacation", "9", null, null, ROUTES.VACATION),
        getItem("Keywords", "10", null, null, ROUTES.KEYWORDS),
      ]),
    ]),
    getItem("Plan", "11", <DashboardOutlined />, null, ROUTES.PLAN),
  ];

  interface LevelKeysProps {
    key?: string;
    children?: LevelKeysProps[];
  }
  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {};
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          return func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(items as LevelKeysProps[]);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    // appService.fetchDids(baseUrl+"", '5');
  };
  const handleMenu = (item: any) => {
    setSelectedKey([item.key]);
    navigate(item?.item?.props?.target);
  };
  function clearLocalStorage(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        resolve();
      } catch (error) {
        reject();
      }
    });
  }
  const loggedIn = useSelector(
    (state: any) => state?.features?.login?.userData
  );

  function onPressLogout() {
    dispatch(
      RequestAppAction.userSignOut({
        cbSuccess: async () => {
          persistor.pause();
          await persistor.flush();
          await persistor.purge().then(() => {
            clearLocalStorage()
              .then(() => {
                dispatch(clearDid());
                navigate("/login");
              })
              .catch(() => {
                toast.error("Something went wrong");
              });
          });
        },
        cbFailure: (e: string) => {
          notification.error({
            message: e ?? t("notification.signOutFail")
          });
        },
      })
    );
  }

  useEffect(() => {
    if (loggedIn) {
      dispatch(RequestAppAction.fetchDid());
    }
  }, [loggedIn]);

  const SelectedItem = () => {
    const path = window.location.pathname;
    items.map((i: any) => {
      if (Array.isArray(i?.children)) {
        i?.children.map((item: any) => {
          if (item?.children && item?.children?.length > 0) {
            item?.children?.map((e: any) => {
              if (e.target === path) {
                setSelectedKey([e?.key]);
              }
            });
          } else {
            if (item?.target === path) {
              setSelectedKey([item?.key]);
            }
          }
        });
      } else {
        if (i?.target === path) {
          setSelectedKey([i?.key]);
        }
      }
    });
  };

  useEffect(() => {
    SelectedItem();
  }, [window.location.pathname]);

  const onOpenChange: MenuProps["onOpenChange"] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1
    );

    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey])
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  const menu = (
    <Menu
        className="font-size-14 font-weight-400"
        theme="light"
        defaultSelectedKeys={["1"]}
        mode={width && width <= 1200 ? "horizontal" : "inline"}
        onSelect={(e) => {
          if (
            !e?.keyPath?.includes("sub1") &&
            !e?.keyPath.includes("sub3")
          ) {
            setStateOpenKeys([e.key]);
          }
        }}
        openKeys={stateOpenKeys}
        onOpenChange={onOpenChange}
        selectedKeys={selectedKey}
        items={items}
        style={{ flex: 1, minWidth: 0 }}
        onClick={(item) => handleMenu(item)}
      />
  );

  return (
    <Layout>
      <Spin
        spinning={
          signoutLoading ||
          (window.location.pathname !== "/setting/time-of-day" && didLoading) ||
          conversationsLoading
        }
      >
        <Header
          user={loggedIn?.username || loggedIn?.email.split("@")[0]}
          onLogout={onPressLogout}
          dids={didDetails}
          onClick={() => navigate("/")}
        />
        <Layout style={{ minHeight: "calc(100vh - 64px)" }}>
          {width && width > 1200 ? (
            <Sider
              trigger={
                <Title level={5}>
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Title>
              }
              collapsible
              collapsed={collapsed}
              theme="light"
              onCollapse={(value) => setCollapsed(value)}
              style={{
                minHeight: "80%",
                boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.15)",
              }}
              width={200}
            >
              {menu}
            </Sider>
          ) : (
            <AntdHeader
              className="dashboard-menu"
              style={{
                width: "100%",
                boxShadow: "2px 2px 2px rgba(0, 0, 0, 0.15)",
                padding: 0,
              }}
            >
              {menu}
            </AntdHeader>
          )}
          <Content className="dashboard-content">
            <DashboardRoutes />
          </Content>
        </Layout>
      </Spin>
    </Layout>
  );
};

export default Dashboard;
