import { AxiosResponse } from "axios";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { AppService } from "src/services/app";
import { LocalStorageService } from "src/services/local-storage";
import { getBaseUrl } from "../selectors/features/user";
import { logoutUser } from "../slices/features/auth";
import { clearAutoResponder } from "../slices/features/autoResponder";
import { clearKeywords } from "../slices/features/autoresponderKeyword";
import { clearContacts } from "../slices/features/contacts";
import { clearConversation } from "../slices/features/conversations";
import { clearConversationById } from "../slices/features/conversationsById";
import { clearCurrentDid } from "../slices/features/currentDid";
import { clearContactUser } from "../slices/features/currentUserContact";
import { clearConversationUser } from "../slices/features/currentUserConversation";
import { clearDid } from "../slices/features/fetchDids";
import { clearGroupContacts } from "../slices/features/groupContacts";
import { clearGroupMessages } from "../slices/features/groupMessages";
import { clearGroupData } from "../slices/features/groups";
import { clearSelectedGroup } from "../slices/features/selectedGroup";
import {
  postSignoutFailure,
  postSignoutSuccess,
} from "../slices/features/signout";
import { clearFiles } from "../slices/features/uploadFiles";
import { clearUser } from "../slices/features/user";
import { clearWorkingDays } from "../slices/features/workingDays";
import { RequestTypes } from "../types";

const appService = new AppService();

const localStorageService = new LocalStorageService();

function* postSignout(action: any) {
  const { payload } = action;

  try {
    const baseUrl: string = yield select(getBaseUrl);

    const response: AxiosResponse<any> = yield call(
      appService.postSignout,
      baseUrl
    );

    yield put(logoutUser());
    yield call(localStorageService.remove, "user");
    yield call(localStorageService.remove, "userData");
    yield call(localStorageService.remove, "authToken");
    yield call(localStorageService.remove, "preferences");
    yield put(clearAutoResponder());
    yield put(clearContactUser());
    yield put(clearContacts());
    yield put(clearConversation());
    yield put(clearConversationById());
    yield put(clearConversationUser());
    yield put(clearDid());
    yield put(clearCurrentDid());
    yield put(clearFiles());
    yield put(clearGroupContacts());
    yield put(clearGroupData());
    yield put(clearGroupMessages());
    yield put(clearKeywords());
    yield put(clearWorkingDays());
    yield put(clearUser());
    yield put(clearSelectedGroup());

    payload?.cbSuccess && payload?.cbSuccess();

    yield put(postSignoutSuccess({ ...response }));
  } catch (error: any) {
    const { statusCode, statusText } = error;

    if (statusCode === 404) {
      yield put(logoutUser());
      yield call(localStorageService.remove, "user");
      yield call(localStorageService.remove, "userData");
      yield call(localStorageService.remove, "authToken");
      yield call(localStorageService.remove, "preferences");
      yield put(clearAutoResponder());
      yield put(clearContactUser());
      yield put(clearContacts());
      yield put(clearConversation());
      yield put(clearConversationById());
      yield put(clearConversationUser());
      yield put(clearDid());
      yield put(clearCurrentDid());
      yield put(clearFiles());
      yield put(clearGroupContacts());
      yield put(clearGroupData());
      yield put(clearGroupMessages());
      yield put(clearKeywords());
      yield put(clearWorkingDays());
      yield put(clearUser());
      yield put(clearSelectedGroup());
    } else {
      yield put(postSignoutFailure({ statusCode, statusText }));
    }
  }
}

export function* watchPostSignout() {
  yield takeLatest(RequestTypes.SIGN_OUT_REQUEST, postSignout);
}
