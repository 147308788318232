import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { IAddVariable } from "src/constants/types";
import AddVariableList from "./AddVariableList";
import "./add-variable.scss";

const AddVariableBtn = ({
  disabled,
  variableList,
  form,
  textareaRef,
  message,
  onTextChange,
  style = { borderRadius: 50, marginLeft: 10 },
}: {
  disabled: boolean;
  variableList: IAddVariable[];
  form: any;
  textareaRef: any;
  message: string;
  onTextChange: (value: string) => void;
  style?: any;
}) => {
  if (!variableList || variableList.length === 0) {
    return (
      <Button
        disabled={disabled}
        icon={<PlusCircleOutlined />}
        style={style}
      />
    );
  }

  const addVaribleMenu = () => (
    <AddVariableList
      variableList={variableList}
      form={form}
      textareaRef={textareaRef}
      message={message}
      onTextChange={onTextChange}
    />
  );

  return (
    <Dropdown dropdownRender={addVaribleMenu} placement="topRight" arrow>
      <Button
        disabled={disabled}
        icon={<PlusCircleOutlined />}
        style={style}
      />
    </Dropdown>
  );
};

export default AddVariableBtn;
